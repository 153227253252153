import React, { useEffect, useState } from "react";
import ReReSubject from "./re_registration_sub/ReReSubject";

function CoreModuleForm(props) {
  const {
    formik,
    setparentSubjectReSubLength,
    parentSubjectReSubLength,
    setparentSubjectReSubLength1,
    parentSubjectReSubLength1,
    reRegSubject,
    progressionData,
    modulesData
  } = props;

  const [fields, setFields] = useState([]);
  const [fields1, setFields1] = useState([]);
  const [regDropdown, setRegDropdown] = useState([])
  const [maxDropdown, setMaxDropdown] = useState()

  useEffect(() => {
    let filteredKeys = Object.keys(progressionData).filter(key => isNaN(key) == false)
    console.log("okokokokokokokokok",progressionData[filteredKeys[0]]);
    setRegDropdown([progressionData[filteredKeys[0]],progressionData[filteredKeys[1]]])
  },[progressionData])

  function handleAdd(flag=0) {
    if(flag) {
      const values = [...fields1];
      values.push("");
      setFields1(values);
      if(flag) props.setparentSubjectReSubLength1(values);
      else props.setparentSubjectReSubLength1(values);
    }else{
      const values = [...fields];
      values.push("");
      setFields(values);
      if(flag) props.setparentSubjectReSubLength(values);
      else props.setparentSubjectReSubLength(values);
    }
  }

  function handleRemove(i,flag=0) {
    if(flag){
      const values = [...fields1];
      if (values.length > 1) {
        values.splice(i, 1);
        setFields1(values);
        props.setparentSubjectReSubLength1(values);
      }
    }else{
      const values = [...fields];
      if (values.length > 1) {
        values.splice(i, 1);
        setFields(values);
        props.setparentSubjectReSubLength(values);
      }
    }
  }

  function handleSubjectChange(i, event,key) {
    const values = [...fields];
    values[i] = event.target.value;
    setFields(values);
    props.setparentSubjectReSubLength(values);
  }

  useEffect(() => {
    console.log("KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK",regDropdown);
  },[regDropdown])

  useEffect(() => {
    console.log("PPPPPPPPPPPPPPPPPPP",modulesData);
    
  },[modulesData])
  let totalCredits = 0;


  return (
    <>
    <h5 className="textdanger heading-5 mt-30"> Core Modules</h5>
    {modulesData.map((d, moduleIdx) => (
      <div key={moduleIdx} className="mb-2 mt-3">
        <b>{d.name}</b> {
        d.modules.modules.map((data,key) => {
          totalCredits += data.credits
          if(key == d.modules.modules.length - 1) {
            return `(${totalCredits} Credits)`
          }
        })}
        <span>
          <b className="credits-outstanding ml-2">Credits Outstanding: {d.modules.credits_outstanding}</b>
        </span>
        {formik.values.subjectReSub_core.map((item, idx) => (
          <div key={idx} className="row">
            <div className="col-sm-6">
              <select
                className="form-control"
                name={`subjectReSub_[${idx}].subject`}
                onChange={(e) => handleSubjectChange(idx, e,d.key)}
                onBlur={formik.handleBlur}
                value={formik.values.subjectReSub_core[idx].subject}
              >
                <option value="">Select</option>
                <ReReSubject reRegSubject={d.modules.modules} />
              </select>

              {formik.touched.subjectReSub_ &&
              formik.errors.subjectReSub_ &&
              formik.touched.subjectReSub_[idx] &&
              formik.errors.subjectReSub_[idx] &&
              formik.touched.subjectReSub_[idx].subject &&
              formik.errors.subjectReSub_[idx].subject ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {formik.errors.subjectReSub_[idx].subject}
                </div>
              ) : null}
            </div>
            <div className="col pl-0" title="Delete">
              <a
                href="#"
                className="btn btn-danger"
                onClick={() => handleRemove(idx)}
              >
                <i className="fal fa-trash-alt"></i>
              </a>
            </div>
          </div>
        ))}
        {(formik.values.subjectReSub_core.length != d.modules.modules.length) && <div className="form-group m-0">
          <a
            href="#"
            className="btn btn-link p-0 btn-additional"
            onClick={() => handleAdd()}
          >
            <i className="fal fa-plus"></i> Add additional line item
          </a>
        </div>}
      </div>
    ))}
      
    </>
  );
}

export default CoreModuleForm;
