import React, { useState } from 'react';


const UploadSignatureFile = () => {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // Handle file upload here
      console.log("File dropped:", e.dataTransfer.files[0].name);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // Handle file upload here
      console.log("File selected:", e.target.files[0].name);
    }
  };

  return (
    <div 
      className={`border-2 border-dashed rounded-lg p-6 text-center ${
        dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
      }`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      {/* <Upload className="mx-auto h-12 w-12 text-gray-400" /> */}
      <i className='fal fa-upload' style={{fontSize : '48px',color : '#467FCF'}}></i>
      <p className="mt-2 text-sm text-gray-600">
        To upload your signature, Drop your file here, or{' '}
        <label 
          htmlFor="file-upload" 
          className="font-medium text-blue-600 hover:underline cursor-pointer"
        >
          click here to browse
        </label>
      </p>
      <input
        id="file-upload"
        name="file-upload"
        type="file"
        className="d-none"
        onChange={handleChange}
        accept=".jpg,.png"
      />
      <p className="mt-1 text-xs text-gray-500">
        Supported Files: JPG and PNG
      </p>
    </div>
  );
};

export default UploadSignatureFile;