import React, { useEffect, useState } from "react";
import ReReSubject from "./re_registration_sub/ReReSubject";

function ElectiveModuleForm(props) {
  const {
    formik,
    setparentSubjectReSubLength,
    parentSubjectReSubLength,
    setparentSubjectReSubLength1,
    parentSubjectReSubLength1,
    reRegSubject,
    progressionData
  } = props;

  const [fields, setFields] = useState([{ subject: "" }]);
  const [fields1, setFields1] = useState([{ subject: "" }]);

  function handleAdd(flag=0) {
    if(flag){
      const values = [...fields1];
      values.push({ subject: "" });
      setFields1(values);
      props.setparentSubjectReSubLength1(values);
    }else{
      const values = [...fields];
      values.push({ subject: "" });
      setFields(values);
      props.setparentSubjectReSubLength(values);
    }
  }

  function handleRemove(i,flag=0) {
    if(flag){
      const values = [...fields1];
      if (values.length > 1) {
        values.splice(i, 1);
        setFields1(values);
        props.setparentSubjectReSubLength1(values);
      }
    }else{
      const values = [...fields];
      if (values.length > 1) {
        values.splice(i, 1);
        setFields(values);
        props.setparentSubjectReSubLength(values);
      }
    }
  }

  function handleSubjectChange(i, event) {
    const values = [...fields];
    values[i].subject = event.target.value;
    setFields(values);
    props.setparentSubjectReSubLength(values);
  }


  
  return (
    <>
            <h5 className="textdanger heading-5 mt-30"> Elective Modules</h5>
            <div className="mb-2 mt-3"><b>Unit 1</b> (30 Credits)<span><b className="credits-outstanding ml-2"> Credits Outstanding: 10 </b></span></div>
      {formik.values.subjectReSub_elective.map((item, idx) => (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-6">
              <select
                className="form-control"
                name={`subjectReSub_[${idx}].subject`}
                onChange={(e) => handleSubjectChange(idx, e)}
                onBlur={formik.handleBlur}
                key={idx}
                value={formik.values.subjectReSub_elective[idx].subject}
              >
                <option value="">Select</option>
                <ReReSubject reRegSubject={reRegSubject} />
              </select>

              {formik.touched.subjectReSub_ &&
              formik.errors.subjectReSub_ &&
              formik.touched.subjectReSub_[idx] &&
              formik.errors.subjectReSub_[idx] &&
              formik.touched.subjectReSub_[idx].subject &&
              formik.errors.subjectReSub_[idx].subject ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {formik.errors.subjectReSub_[idx].subject}
                </div>
              ) : null}
            </div>
            <div className="col pl-0" title="Delete">
              <a
                href
                className="btn btn-danger"
                onClick={() => handleRemove(idx)}
              >
                <i className="fal fa-trash-alt"></i>
              </a>
            </div>
          </div>
        </React.Fragment>
      ))}
      <React.Fragment>
        <div className="form-group m-0">
          <a
            href
            className="btn btn-link p-0 btn-additional"
            onClick={() => handleAdd()}
          >
            <i className="fal fa-plus"></i> Add additional line item
          </a>
        </div>
      </React.Fragment>


      <div className="mb-2 mt-3"><b>Unit 2</b> (20 Credits)<span><b className="credits-outstanding ml-2"> Credits Outstanding: 5 </b></span></div>
      {formik.values.subjectReSub_elective1.map((item, idx) => (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-6">
              <select
                className="form-control"
                name={`subjectReSub_[${idx}].subject`}
                onChange={(e) => handleSubjectChange(idx, e)}
                onBlur={formik.handleBlur}
                key={idx}
                value={formik.values.subjectReSub_elective1[idx].subject}
              >
                <option value="">Select</option>
                <ReReSubject reRegSubject={reRegSubject} />
              </select>

              {formik.touched.subjectReSub_ &&
              formik.errors.subjectReSub_ &&
              formik.touched.subjectReSub_[idx] &&
              formik.errors.subjectReSub_[idx] &&
              formik.touched.subjectReSub_[idx].subject &&
              formik.errors.subjectReSub_[idx].subject ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {formik.errors.subjectReSub_[idx].subject}
                </div>
              ) : null}
            </div>
            <div className="col pl-0" title="Delete">
              <a
                href
                className="btn btn-danger"
                onClick={() => handleRemove(idx,1)}
              >
                <i className="fal fa-trash-alt"></i>
              </a>
            </div>
          </div>
        </React.Fragment>
      ))}
      <React.Fragment>
        <div className="form-group m-0">
          <a
            href
            className="btn btn-link p-0 btn-additional"
            onClick={() => handleAdd(1)}
          >
            <i className="fal fa-plus"></i> Add additional line item
          </a>
        </div>
      </React.Fragment> 
      
    </>
  );
}

export default ElectiveModuleForm;
