import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonForm = ({height=1,width=1}) => {
    return (
        <>
            <Skeleton height={height} width={width}/>
            <Skeleton height={height} width={width}/>
            <Skeleton height={height} width={width}/>
            <Skeleton height={height} width={width}/>
            <Skeleton height={height} width={width}/>
            <Skeleton height={height} width={width}/>
            <Skeleton height={height} width={width}/>
            <Skeleton height={height} width={width}/>
            <Skeleton height={height} width={width}/>
            <Skeleton height={height} width={width}/>
            <Skeleton height={height} width={width}/>
        </>
    );
}

export default SkeletonForm;
