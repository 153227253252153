import React, { useRef, useEffect, useState } from 'react';
import SignaturePad from 'signature_pad';
const TypeSignatureComponent = ({signaturePadRef}) => {
    const canvasRef = useRef(null);
    const [isEmpty, setIsEmpty] = useState(true);
    const [erasorDisplay,setErasorDisplay] = useState(true)
    const [fullStack,setFullStack] = useState([])
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const fontStyles = [
        { name: 'Sacramento', googleFont: 'Sacramento' },
        { name: 'La Belle Aurore', googleFont: 'La+Belle+Aurore' },
        { name: 'Freehand', googleFont: 'Freehand' },
        { name: 'Dancing Script', googleFont: 'Dancing+Script' },
        { name: 'Handlee', googleFont: 'Handlee' },
        { name: 'Cedarville Cursive', googleFont: 'Cedarville+Cursive' },
    ];
    const [selectedFont, setSelectedFont] = useState(fontStyles[0])
    
    return (
        <div className="max-w-md position-relative signature-pad-outer">
            <div className="px-30 border border-gray-300 rounded-lg overflow-hidden" style={{height : "198px"}}>
                <input type='text' style={{fontFamily : selectedFont}}/>
                <hr className='mt-0'/>
                <div className="my-2 left-0 right-0 text-center text-gray-400">
                    Sign Above
                </div>
                <div className='position-absolute d-flex' style={{top : '10px',right: '10px',gap:'10px'}}>
                   <span style={{color : '#467FCF'}}>Change Style <i className='fal fa-chevron-down'></i></span>
                   <select>
                    {fontStyles.map((style, index) => (
                        <option key={index} onClick={() => setSelectedFont(style.googleFont)} style={{fontFamily : style.googleFont}} value={style.googleFont}>{style.name}</option>
                    ))}
                   </select>
                </div>
            </div>
        </div>
    );
};
export default TypeSignatureComponent;