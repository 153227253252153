import React, { useRef, useState } from 'react';
import SignaturePadComponent from './SignaturePadComponent';
import TypeSignatureComponent from './TypeSignatureComponent';
import UploadSignatureFile from './UploadSignatureFile';
const DigitalSignatureComponent = ({signaturePadRef}) => {
  const [signatureType, setSignatureType] = useState(0);
    return (
        <div>
            <div className="mt-3 mb-1">Signature*</div>
                <div className="d-flex gap-2 mb-1 py-1">
                  <div className="border-b-black" style={{borderBottom : signatureType == 0 ? '1px #467FCF solid' : '0',color : signatureType == 0 ? '#467FCF' : 'black',cursor:'pointer'}} onClick={() => {setSignatureType(0)}}>
                    <i className="fal fa-signature"></i>
                    <span className="ml-1">Draw</span>
                  </div>
                  <div className="border-b-black" style={{borderBottom : signatureType == 1 ? '1px #467FCF solid' : '0',color : signatureType == 1 ? '#467FCF' : 'black',cursor:'pointer'}} onClick={() => {setSignatureType(1)}}>
                    <i className="fal fa-keyboard"></i>
                    <span className="ml-1">Type</span>
                  </div>
                  <div className="border-b-black" style={{borderBottom : signatureType == 2 ? '1px #467FCF solid' : '0',color : signatureType == 2 ? '#467FCF' : 'black',cursor:'pointer'}} onClick={() => {setSignatureType(2)}}>
                    <i className="fal fa-image"></i>
                    <span className="ml-1">Upload Image </span>
                  </div>
                </div>
                {signatureType == 0 && (
                  <>
                    <SignaturePadComponent signaturePadRef={signaturePadRef}/>
                    {/* <i className="fal fa-info fa-circle"></i> */}
                  </>
                )}
               {signatureType == 1 &&
                <TypeSignatureComponent signaturePadRef={signaturePadRef}/>}
                {signatureType == 2 && 
                  <UploadSignatureFile/>
                }
                {new Date().toLocaleDateString()}
        </div>
    );
}
export default DigitalSignatureComponent;