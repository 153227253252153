import React, { useRef, useEffect, useState } from 'react';
import SignaturePad from 'signature_pad';

const SignaturePadComponent = ({signaturePadRef}) => {
    const canvasRef = useRef(null);
    const [isEmpty, setIsEmpty] = useState(true);
    const [erasorDisplay,setErasorDisplay] = useState(true)
    const [fullStack,setFullStack] = useState([])
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [tool, setTool] = useState('pencil');

    useEffect(() => {
        const canvas = canvasRef.current;
        const signaturePad = new SignaturePad(canvas, {
            backgroundColor: 'rgb(255, 255, 255)',
            penColor: 'rgb(0, 0, 0)'
        });
        signaturePadRef.current = signaturePad;

        const checkEmpty = () => {
            console.log("this is actual",signaturePad.toData());
            setFullStack(signaturePad.toData())
            setIsEmpty(signaturePad.isEmpty());
            setUndoStack(signaturePad.toData());
            setRedoStack([]);
        };
        signaturePad.addEventListener('endStroke', checkEmpty);

        return () => {
            signaturePad.removeEventListener('endStroke', checkEmpty);
            signaturePad.clear();
        };
    }, []);

    useEffect(() => {
        console.log("HHHHHHHHHHHhhh",undoStack,redoStack,fullStack);
    },[undoStack])

    const clearSignature = () => {
        signaturePadRef.current.clear();
        setIsEmpty(true);
    };

    const saveSignature = () => {
        if (signaturePadRef.current.isEmpty()) {
            alert("Please provide a signature first.");
        } else {
            const dataURL = signaturePadRef.current.toDataURL();
            console.log(dataURL);
            return dataURL
            // You can also upload this data URL to your server
        }
    };

    const erase = () => {
        signaturePadRef.current.penColor = 'rgb(255, 255, 255)';
        setErasorDisplay(false)
        setTool('eraser')
    }
    const draw = () => {
        signaturePadRef.current.penColor = 'rgb(0, 0, 0)';
        setErasorDisplay(true)
        setTool('pencil')
    }
    const undo = () => {
        const data = signaturePadRef.current.toData();
        if (data.length > 0) {
            data.pop(); // remove the last stroke
            signaturePadRef.current.clear(); // Clear the canvas
            signaturePadRef.current.fromData(data);
            setUndoStack(prevStack => prevStack.slice(0, -1));
            setRedoStack(signaturePadRef.current.toData());
            setIsEmpty(signaturePadRef.current.isEmpty());
        }
    };
    const redo = () => {
        if (redoStack.length > 0) {
            const strokeToRedo = fullStack[undoStack.length] || []
            const newData = [...signaturePadRef.current.toData(), strokeToRedo];
            signaturePadRef.current.clear();
            signaturePadRef.current.fromData(newData);
            setUndoStack(prevStack => [...prevStack, strokeToRedo]);
            setRedoStack(prevStack => prevStack.slice(0, -1));
            setIsEmpty(false);
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (tool === 'pencil') {
          canvas.style.cursor = 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'currentColor\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3E%3Cline x1=\'18\' y1=\'2\' x2=\'22\' y2=\'6\'%3E%3C/line%3E%3Cpath d=\'M7.5 20.5 19 9l-4-4L3.5 16.5 2 22z\'%3E%3C/path%3E%3C/svg%3E") 0 24, auto';
        } else if (tool === 'eraser') {
          canvas.style.cursor = 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'currentColor\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3E%3Cpath d=\'m7 21-4.3-4.3c-1-1-1-2.5 0-3.4l9.6-9.6c1-1 2.5-1 3.4 0l5.6 5.6c1 1 1 2.5 0 3.4L13 21\'%3E%3C/path%3E%3Cpath d=\'M22 21H7\'%3E%3C/path%3E%3Cpath d=\'m5 11 9 9\'%3E%3C/path%3E%3C/svg%3E") 0 24, auto';
        }
      }, [tool]);
    

    return (
        <div className="max-w-md position-relative signature-pad-outer">
            <div className="px-30 border border-gray-300 rounded-lg overflow-hidden">
                <canvas
                    ref={canvasRef}
                    style={{position:'relative',left: '20px'}}
                />
                <hr className='mt-0'/>
                <div className="my-2 left-0 right-0 text-center text-gray-400">
                    Sign Above
                </div>
                <div className='position-absolute d-flex' style={{top : '10px',right: '10px',gap:'10px'}}>
                    <i role='button' onClick={undo} className='fal fa-undo'></i>
                    <i role='button' onClick={redo} className='fal fa-redo'></i>
                    {
                        erasorDisplay ? 
                        <i role='button' onClick={erase} className='fal fa-eraser'></i>
                        :
                        <i role='button' className='fal fa-pencil' onClick={draw}></i>   
                    }
                </div>
            </div>
        </div>
    );
};

export default SignaturePadComponent;